import React from "react"
import Styles from "./top.module.css"
import TopPhoto from "./TopPhotos"
import photo from "./photos/1.jpg"
import p2 from "./photos/2.jpg"
import p3 from "./photos/3.jpg"

// import "boxicons"
// import Share from "./shapes.svg"
console.log("pppppp>" + photo)
const Top = (props) => {
    return (
        <div className={Styles["main-top"]}>
            <h1>Top 50 Hits From TV Shows</h1>
            <div className={Styles["next"]}>
                <div className={Styles["btn"]}>Play All</div>
                <div className={Styles["share"]}>
                    {" "}
                    <i className="bx bxs-share-alt bx-md"></i>
                    <span className={Styles["share-text"]}>
                        share ({props.share})
                    </span>
                </div>
                <div className={Styles["comment"]}>
                    <i className="bx bx-comment-detail bx-md"></i>
                    <span className={Styles["comments"]}>
                        comments ({props.comments})
                    </span>
                </div>
            </div>

            <div className={Styles["photos"]}>
                <TopPhoto
                    text="All movies"
                    color="rgb(255,0,0,0.2)"
                    image={photo}
                />
                <TopPhoto
                    text="Global Top 50"
                    color="rgb(23,5,64,0.4)"
                    image={p2}
                />
                <TopPhoto
                    text="Top 50 in Your Region"
                    color="rgb(23,125,64,0.3)"
                    image={p3}
                />
                {/* <TopPhoto image={p4} /> */}
            </div>
        </div>
    )
}

export default Top


