import React from "react"
import Styles from "./footer.module.css"
import Moods from "./Moods"
const footer = (props) => {
    return (
        <div className={Styles["main"]}>
            <div className={Styles["def"]}>
                <div className={Styles["header"]}>
                    <span>Moods Mixes</span>
                </div>
                <div className={Styles["middle"]}>
                    <Moods />
                    <Moods />
                    <Moods />
                    <Moods />
                </div>
            </div>
        </div>
    )
}

export default footer
