import React from "react"
import Styles from "./moods.module.css"
import mainF from "./components/layout/photos/4.jpg"
const moods = (props) => {
    const imageStyle = {
        background: `url(${mainF}) center center /cover`,
        // borderRadius: "5px",
    }
    return (
        <div className={Styles["main"]}>
            <div className={Styles["image"]} style={imageStyle}></div>
            <div className={Styles.disc}>
                <h2>the Topic of image</h2>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Expedita, ut nostrum.Cumque a quis possimus, hic totam
                    veritatis rem assumens voluptas provident!
                </p>
            </div>
        </div>
    )
}

export default moods
