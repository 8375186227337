import React from "react"
import "./App.css"
import Navbar from "./components/layout/Navbar"
import Top from "./components/layout/Top"
import Middle from "./Middle"
import Footer from "./Footer"

function App() {
    return (
        <div className="container">
            <Navbar />
            <Top share="14" comments="23" />
            <Middle />
            <Footer />
        </div>
    )
}

export default App
