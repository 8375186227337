import React from "react"
import Styles from "./topphoto.module.css"

const TopPhoto = (props) => {
    const theStyle = {
        background: `linear-gradient(${props.color}, ${props.color}) ,url(${props.image}) `,
        backgroundSize: "cover",
        borderRadius: "5px",
    }

    return (
        <div style={theStyle} className={Styles["main"]}>
            {/* <img src={props.image} alt="mainTopImages" /> */}
            <span className={Styles["text"]}>{props.text}</span>
        </div>
    )
}

export default TopPhoto
