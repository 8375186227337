import React from "react"
import Styles from "./musicbar.module.css"

const musicBar = (props) => {
    return (
        <div className={Styles["main"]}>
            <div className={Styles["number"]}>1</div>
            <div className={Styles["pic"]}></div>
            <div className={Styles["info"]}>
                <h2 className={Styles["music-title"]}>Nemitarsam</h2>
                <p className={Styles["artist"]}>Ali Sorena</p>
            </div>
            <div className={Styles["time"]}> 04:23</div>
            <div className={Styles["icons"]}>
                <i className="bx bxs-share-alt bx-md"></i>
                <span className={Styles["more"]}>...</span>
            </div>
        </div>
    )
}

export default musicBar
