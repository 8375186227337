import React from "react"
import Styles from "./Navbar.module.css"
const Navbar = (props) => {
    console.log(Styles)
    return (
        <div className={Styles["nav"]}>
            <ul>
                <li className={Styles["item"]}>Recommended</li>
                <li className={Styles["item"]}>New Releases</li>
                <li className={Styles["item"]}>Top charts</li>
                <li className={Styles["item"]}>Moods</li>
                <li className={Styles["item"]}>About you</li>
            </ul>

            <div className={Styles['insted-nav']}><i class='bx bxs-dashboard'></i></div>
        </div>
    )
}

export default Navbar
