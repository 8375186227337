import React from "react"
import Styles from "./middle.module.css"
import MusicBar from "./MusicBar"
const middle = (props) => {
    return (
        <div className={Styles["settings"]}>
            {/* <hr  className={Styles['line']} /> */}
            <div className={Styles["main"]}>
                <div className={Styles["left"]}>
                    <span>Most Popular This Week</span>
                    <MusicBar />
                    <MusicBar />
                    <MusicBar />
                    <MusicBar />
                    <MusicBar />
                    <span className={Styles["show-more-less"]}>SHOW MORE</span>
                </div>
                <div className={Styles["right"]}>
                    <span>Most Recommended</span>
                    <MusicBar />
                    <MusicBar />
                    <MusicBar />
                    <MusicBar />
                    <MusicBar />
                    <span className={Styles["show-more-less"]}>SHOW MORE</span>
                </div>
            </div>
        </div>
    )
}

export default middle
